<template>
  <div class="px-3 pb-5">
    <PageHeader title="펀다 일반 상품 상세" />

    <v-row no-gutters class="mt-5">
      <v-col cols="12" class="mb-5">
        <FundingDataSearch @selectFundingData="(v) => (selected = v)" />
      </v-col>

      <v-col cols="6" class="pr-2">
        <div class="notice-popup__contents pa-6">
          <div class="popup-header d-flex justify-space-between align-center">
            <h2 class="font-size--20 font-weight-black my-0">기본 정보</h2>
          </div>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="notice-popup__contents pa-6">
          <div v-if="selected" class="text-right">
            <v-btn
              color="#393939"
              class="white--text"
              height="41"
              depressed
              @click="noticeDialog = true"
            >
              투자자공지 수정하기
            </v-btn>
          </div>
          <div class="popup-header d-flex justify-space-between align-center">
            <h2 class="font-size--20 font-weight-black my-0">
              연체&#47;부실 안내
            </h2>
          </div>
          <div
            v-html="notice"
            class="notice-popup__text mt-5 overflow-y--scroll"
          ></div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="noticeDialog" max-width="800px" scrollable>
      <v-card>
        <v-card-title> 투자자 공지 수정 </v-card-title>
        <v-card-text style="height: 700px">
          <v-textarea
            v-if="selected"
            v-model="selected.bankruptcyNoticeForCreditors"
            rows="22"
            row-height="16"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="#393939"
            class="white--text"
            :loading="isLoading"
            :disabled="isLoading"
            @click="modify()"
          >
            저장
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FundingDataSearch from '@/components/funda/funding/FundingDataSearch'
export default {
  components: {
    FundingDataSearch,
  },
  data() {
    return {
      isLoading: false,
      selected: null,
      noticeDialog: false,
    }
  },
  computed: {
    notice() {
      const txt = this.selected?.bankruptcyNoticeForCreditors?.replace(
        /(?:\r\n|\r|\n)/g,
        '<br />'
      )
      return txt
    },
  },
  methods: {
    async modify() {
      const confirm = await this.$swal.basic.confirm('상품을 수정합니다?')
      if (confirm.isConfirmed === false) {
        return
      }

      this.isLoading = true

      const q = `
        data: modifyFundingData(inputFundingData: $input)
          {
            idx
            storeIdx
            title
            subTitle
            visible
            isBlind
            isEasyLoan
            extApiOpen
            totalAmount
            minInvestAmount
            maxInvestAmount
            unitInvestAmount
            monthlyRepaymentGrace
            monthlyRepayment
            interestRate
            accruedInterestRate
            fundaSupportInterestRate
            commissionRate
            repaymentMethod
            loanPeriod
            gracePeriod
            contractDate
            beginningDate
            interestDate
            initialAccountInterestDate
            monthlyRepaymentDate
            score
            fundaGrade
            defaultRate
            niceUse
            niceGrade
            niceDefaultRate
            kcbUse
            kcbGrade
            kcbDefaultRate
            updateDate
            disclosureDate
            investmentStartDate
            investmentClosureDate
            registerDate
            investmentApplicationDate
            fundingStyle
            isActual
            fundingType
            investmentType
            fundingRepresentativeFeature
            provisionFundIdx
            provisionFundAmount
            additionalInterestFlag
            status
            repaymentStatus
            depositStatus
            updateOverdueInterest
            personInCharge
            purpose1
            purpose2
            purpose3
            additionalInfoJson
            infoFromFundaSocialJson
            rowConcat
            rowChecksum
            memo
            mainBondStyle
            bankruptcyNotice
            bankruptcyNoticeForCreditors
            interestRateGrace
            bcNb
            periodSelectionParentIdx
            topNotice
            teamInCharge
            isAutoRefund
            isPercent
            refundAmount
            
            store {
              idx
              
              user {
                name
              }
            }
          }
        `

      try {
        const inputFundingData = { ...this.selected }
        delete inputFundingData.store

        const data = await this.$fundaApi.mutation(
          gql`{${q}}`,
          'call($input: InputFundingData!)',
          { input: inputFundingData }
        )

        this.selected = data

        this.$swal.basic.success('정보가 수정되었습니다.')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.notice-popup {
  &__contents {
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    background-color: white;
    z-index: 999;
  }

  &__text {
    min-height: 502px;
    max-height: 800px;
  }
}
</style>
